import React, { useState } from 'react'
import './XVarChart.scss'
import {
  Chart,
  Size,
  Series,
  Legend,
  ValueAxis,
  VisualRange,
  Label,
  ConstantLine,
  Export,
  Tooltip,
  ZoomAndPan,
  ScrollBar,
  Crosshair,
  Font,
  ArgumentAxis,
  HorizontalLine,
  VerticalLine,
  HorizontalLineLabel,
  CommonSeriesSettings,
  Point
} from 'devextreme-react/chart'
import 'react-moment'
import XVarChartTooltip from './XVarChartTooltip'

const XVarChart = (props) => {
  const [AnalysisTargetData, setAnalysisTargetData] = useState({})
  const [xVarChartXYdata, setXVarChartXYdata] = useState([])
  const [highAverage, setHighAverage] = useState(props.AnalysisTargetData.xUcl)
  const [lowAverage, setLowAverage] = useState(props.AnalysisTargetData.xLcl)
  const [visualRange, setVisualRange] = useState({})

  const customizePoint = (arg) => {
    if (arg.value > highAverage) {
      return { color: '#ff7c7c', hoverStyle: { color: '#ff7c7c' } }
    } else if (arg.value < lowAverage) {
      return { color: '#8c8cff', hoverStyle: { color: '#8c8cff' } }
    }
  }
  const customizeLabel = (arg) => {
    if (arg.value > highAverage) {
      return {
        visible: true,
        backgroundColor: '#ff7c7c',
        customizeText: function (e) {
          return `${e.valueText}&#176`
        }
      }
    }
  }
  const customizeText = (arg) => {
    return `${arg.valueText}&#176`
  }

  const updateVisualRange = (e) => {
    setVisualRange(e.value)
  }
  const xUclText = '[X_Ucl] : ' + props.AnalysisTargetData.xUcl
  const xClText = '[X_Cl] : ' + props.AnalysisTargetData.xCl
  const xLclText = '[X_Lcl] : ' + props.AnalysisTargetData.xLcl
  const uslText = '[Usl] : ' + props.AnalysisTargetData.usl
  const lslText = '[Lsl] : ' + props.AnalysisTargetData.lsl
  const clText = '[Cl] : ' + props.AnalysisTargetData.cl

  return (
    //  X_UCL  X_CL  X_LCL Green
    // USL LSL CL Led Line
    <div className="dx-card">
      <Chart
        className="chart"
        id="chart"
        dataSource={props.xVarChartXYdata}
        customizePoint={customizePoint}
        customizeLabel={customizeLabel}
      >
        <CommonSeriesSettings argumentField="complaint" hoverMode="includePoints">
          <Point hoverMode="allArgumentPoints" />
        </CommonSeriesSettings>
        <Size height={850} />
        <Series
          name="xVarVal"
          argumentField="inspTime"
          valueField="xVarVal"
          type="line"
          color="#679EC5"
        />
        {/* <Series argumentField="inspTime" valueField="xVarVal" type="line" color="#e7d19a" /> */}
        <ArgumentAxis valueMarginsEnabled={false}>
          <Label format="shortDateShortTime" />
        </ArgumentAxis>
        <ValueAxis maxValueMargin={0.01}>
          <VisualRange
            startValue={lowAverage}
            endValue={highAverage}
            // visualRangeUpdateMode="auto"
          />
          <ConstantLine
            width={2}
            value={props.AnalysisTargetData.xUcl}
            color="#004202"
            dashStyle="dash"
          >
            <Label text={xUclText}>
              <Font color="#004202"></Font>
            </Label>
          </ConstantLine>
          <ConstantLine
            width={2}
            value={props.AnalysisTargetData.xCl}
            color="#004202"
            dashStyle="dash"
          >
            <Label text={xClText}>
              <Font color="#004202"></Font>
            </Label>
          </ConstantLine>
          <ConstantLine
            width={2}
            value={props.AnalysisTargetData.xLcl}
            color="#004202"
            dashStyle="dash"
          >
            <Label text={xLclText}>
              <Font color="#004202"></Font>
            </Label>
          </ConstantLine>
          <ConstantLine
            width={2}
            value={props.AnalysisTargetData.usl}
            color="#e31c1c"
            dashStyle="dash"
          >
            <Label text={uslText}>
              <Font color="#e31c1c"></Font>
            </Label>
          </ConstantLine>
          <ConstantLine
            width={2}
            value={props.AnalysisTargetData.lsl}
            color="#e31c1c"
            dashStyle="dash"
          >
            <Label text={lslText}>
              <Font color="#e31c1c"></Font>
            </Label>
          </ConstantLine>
          <ConstantLine
            width={2}
            value={props.AnalysisTargetData.cl}
            color="#e31c1c"
            dashStyle="dash"
          >
            <Label text={clText}>
              <Font color="#e31c1c"></Font>
            </Label>
          </ConstantLine>
        </ValueAxis>

        <Crosshair enabled={true} color="#949494" width={3} dashStyle="dot">
          <HorizontalLine color="#637afd">
            <HorizontalLineLabel visible={true} backgroundColor="#637afd" format="decimal">
              <Font color="#fff" size={12} />
            </HorizontalLineLabel>
          </HorizontalLine>
          <VerticalLine color="#f5cc5c">
            <Label visible={true} backgroundColor="#f5cc5c" format="shortDateShortTime">
              <Font color="#fff" size={12} />
            </Label>
          </VerticalLine>
        </Crosshair>
        <Tooltip enabled={true} shared={true} contentRender={XVarChartTooltip} />
        <ScrollBar visible={true} />
        <ZoomAndPan argumentAxis="both" />
        <Legend visible={false} />
        <Export enabled={true} />
      </Chart>
    </div>
  )
}

export default XVarChart
