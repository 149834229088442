import React from 'react'
import { dateFormat } from './../../../common/CommDate'
export default function XVarChartTooltip(info) {
  return (
    <div>
      <div className="tooltip-header">{dateFormat.localeString(info.argumentText)}</div>
      <div className="tooltip-body">
        <div className="series-name">{info.points[0].seriesName}: </div>
        <div className="value-text">{info.points[0].valueText}</div>
      </div>
    </div>
  )
}
