import axios from 'axios'
import { dateFormat } from '../common/CommDate'
let address = 'http://192.168.0.221/api'
axios.defaults.headers.post['language'] = 'KR'
// let param = { inspType: 'TPS010003' }
const getProductList = () => {
  return axios.post(address + '/master/item/products', {}).then((response) => response.data)
}
const getQualityStatisticsAnaysisList = (param) => {
  return axios
    .post(address + '/qcm/qualityStatisticsAnaysis', param)
    .then((response) => response.data)
}
const getCustomLookUp = (param) => {
  return axios.post(address + '/qcm/customLookUp', param).then((response) => response.data)
}
const getMchLookUp = (param) => {
  return axios.post(address + '/qcm/mchLookUp', param).then((response) => response.data)
}
const setUploadResourceMultiFile = (param) => {
  return axios
    .post(address + '/resourceFile/uploadMultiFiles', param)
    .then((response) => response.data)
}
export {
  getProductList,
  getQualityStatisticsAnaysisList,
  getCustomLookUp,
  getMchLookUp,
  setUploadResourceMultiFile
}
