import * as _ from 'lodash'

/* Chart x,y 축 데이터*/
export const XYdataSelect = (list, xyDataList) => {
  let resultList = []

  for (let item of list) {
    let result = {}
    for (const [key, value] of Object.entries(item)) {
      for (let xyData of xyDataList) {
        if (_.isEqual(xyData, key)) {
          if (_.isEqual(key, 'inspTime')) {
            result[key] = new Date(value)
          } else {
            result[key] = value
          }
        }
      }
    }
    resultList.push(result)
  }
  return resultList
}
