import React, { useState } from 'react'

import {
  Chart,
  Size,
  Series,
  Legend,
  ValueAxis,
  Label,
  Export,
  Tooltip,
  ScrollBar,
  ZoomAndPan,
  CommonSeriesSettings,
  Point,
  Crosshair,
  HorizontalLine
} from 'devextreme-react/chart'
import ProcDistributionToolTip from './ProcDistributionToolTip'

const crosshairFormat = {
  type: 'fixedPoint',
  precision: 2
}
const onLegendClick = (e) => {
  e.target.isVisible() ? e.target.hide() : e.target.show()
}
const lengentBorder = {
  dashStyle: 'dash',
  cornerRadius: 4,
  color: '#f50000'
}
const ProcDistribution = (props) => {
  return (
    <div className="dx-card">
      <Chart id="chart" dataSource={props.ProcDistributionXYdata} onLegendClick={onLegendClick}>
        <CommonSeriesSettings argumentField="complaint" hoverMode="includePoints">
          <Point hoverMode="allArgumentPoints" />
        </CommonSeriesSettings>
        <Size height={850} />
        <Series name="빈도" axis="y" argumentField="x" valueField="y" type="bar" color="#e7d19a" />
        <Series
          name="Spec"
          axis="yStdLog"
          argumentField="x"
          valueField="yStd"
          type="line"
          color="#a2402f"
          dashStyle="dash"
        />
        <Series
          name="Log"
          axis="yStdLog"
          argumentField="x"
          valueField="yLog"
          type="line"
          color="#2f37a2"
        />
        <Legend verticalAlignment="bottom" horizontalAlignment="center" border={lengentBorder} />
        <ValueAxis name="y" position="left"></ValueAxis>
        <ValueAxis name="yStdLog" position="right"></ValueAxis>
        <Tooltip enabled={true} shared={true} contentRender={ProcDistributionToolTip} />
        <Crosshair enabled={true}>
          <HorizontalLine visible={true} />
          <Label visible={true} format={crosshairFormat} />
        </Crosshair>
        <ScrollBar visible={true} />
        <ZoomAndPan argumentAxis="both" />
        <Export enabled={true} />
      </Chart>
    </div>
  )
}

export default ProcDistribution
