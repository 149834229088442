import React from 'react'
import * as CommDate from '../../../common/CommDate'
export default function ProcDistributionToolTip(info) {
  let toolText = info.points.map((vlaue) => (
    <div>
      <div className="series-name">{vlaue.seriesName}: </div>
      <div className="value-text">{vlaue.valueText}</div>
    </div>
  ))
  return (
    <div>
      <div className="tooltip-header">x축 : {info.argumentText}</div>
      <div className="tooltip-body">{toolText}</div>
    </div>
  )
}
