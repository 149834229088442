import React, { useEffect, useState } from 'react'
import './home.scss'
import { ResponsiveBox } from 'devextreme-react'
import { Item } from 'devextreme-react/form'
import { Location, Row, Col } from 'devextreme-react/responsive-box'
import { Button } from 'devextreme-react/button'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'
const Home = () => {
  const dispatch = useDispatch()
  const [selectedFiles, setSelectedFiles] = useState(null)

  function downloadManagement() {
    window.open('/FactoryView_Setup.exe', '_self')
  }
  function downloadWorker(e) {
    window.open('/FactoryViewWorker_Setup.exe', '_self')
  }
  function screen(width) {
    return width < 700 ? 'sm' : 'lg'
  }

  const upload = (e) => {
    e.preventDefault()
    fileUpload(selectedFiles).then((response) => {
      console.log(response.data)
      alert(response.data[0].fileName + '이 다운로드 됫습니다.')
    })
  }
  const factoryViewDownLoad = () => {
    window.open('/api/resourceFile/FactoryView_Setup.exe', '_self')
  }
  const factoryViewWorkerDownLoad = () => {
    window.open('/api/resourceFile/FactoryViewWorker_Setup.exe', '_self')
  }
  const factoryViewStatusBoardDownLoad = () => {
    window.open('/api/resourceFile/FactoryViewStatusBoard_Setup.exe', '_self')
  }
  const fileUpload = (file) => {
    const url = '/api/resourceFile/uploadMultiFiles'
    const formData = new FormData()
    formData.append('files', file)
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    return axios.post(url, formData, config)
  }
  const fileChange = (e) => {
    setSelectedFiles(e.target.files[0])
  }
  return (
    <React.Fragment>
      {/* <h2 className={'content-block'}>자료실</h2>
      <div className={'content-block dx-card responsive-paddings'}>
        <form onSubmit={upload}>
          <input type="file" multiple onChange={fileChange} name="file"></input>
          <button type="submit">저장하기</button>
        </form>
      </div> */}
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <ResponsiveBox singleColumnScreen="sm" screenByWidth={screen}>
            <Row ratio={1}></Row>
            <Row ratio={1}></Row>

            <Col ratio={0.48}></Col>
            <Col ratio={0.48}></Col>
            <Col ratio={0.48}></Col>

            <Item>
              <Location row={0} col={0} screen="lg"></Location>
              <Location row={0} col={0} screen="sm"></Location>
              <div className="header item mar10">
                <Button
                  width={'100%'}
                  icon="download"
                  text="관리자 프로그램 다운로드"
                  type="success"
                  stylingMode="contained"
                  //onClick={downloadManagement}
                  onClick={factoryViewDownLoad}
                />
              </div>
            </Item>
            <Item>
              <Location row={0} col={1} screen="lg"></Location>
              <Location row={0} col={0} screen="sm"></Location>
              <div className="header item mar10">
                <Button
                  width={'100%'}
                  icon="download"
                  text="작업자 프로그램 다운로드"
                  type="default"
                  stylingMode="contained"
                  onClick={factoryViewWorkerDownLoad}
                />
              </div>
            </Item>
            <Item>
              <Location row={0} col={2} screen="lg"></Location>
              <Location row={0} col={0} screen="sm"></Location>
              <div className="header item ">
                <Button
                  width={'100%'}
                  icon="download"
                  text="현황판 프로그램 다운로드"
                  type="danger"
                  stylingMode="contained"
                  onClick={factoryViewStatusBoardDownLoad}
                />
              </div>
            </Item>
          </ResponsiveBox>
        </div>
      </div>
    </React.Fragment>
  )
}
export default Home
