/**
    store를 생성하는 함수 createStore를 만들어주었다.

    이 함수는 redux/toolkit에서 제공해주는 configureStore라는 함수를 이용하여 store를 생성한다.
    그리고 sagaMiddleware.run() 을 통해 saga 미들웨어를 실행시킨다.

    이렇게 만든 createStore 함수를 이용해 App.js에서 store를 생성한 후, Provider 컴포넌트에 prop으로 내려줌으로써 모든 설정이 끝난다.

    출처: https://im-developer.tistory.com/195 [Code Playground]
 */
import { combineReducers } from '@reduxjs/toolkit'
import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'

import { all } from 'redux-saga/effects'
import { UNSPLASH, unsplashReducer } from '../pages/view-name/reducers'
import {
  QualityStatisticsAnaysis,
  qualityStatisticsAnaysisReducer
} from '../pages/QualityStatisticsAnaysis/slice'
import { watchUnsplash } from '../pages/view-name/saga'
import { watchQualityStatisticsAnaysis } from '../pages/QualityStatisticsAnaysis/saga'

export const rootReducer = combineReducers({
  [UNSPLASH]: unsplashReducer,
  [QualityStatisticsAnaysis]: qualityStatisticsAnaysisReducer
})

const sagaMiddleware = createSagaMiddleware() //saga 미들웨어를 생성

export function* rootSaga() {
  yield all([watchUnsplash(), watchQualityStatisticsAnaysis()])
}

const createStore = () => {
  const store = configureStore({
    reducer: rootReducer,
    devTools: true,
    middleware: [sagaMiddleware]
  })

  sagaMiddleware.run(rootSaga)

  return store
}

export default createStore
