import React, { useState } from 'react'
import './XVarChart.scss'
import {
  Chart,
  Size,
  Series,
  Legend,
  ValueAxis,
  Label,
  ConstantLine,
  Export,
  Tooltip,
  ZoomAndPan,
  ScrollBar,
  Crosshair,
  Font,
  ArgumentAxis,
  HorizontalLine,
  VerticalLine,
  CommonSeriesSettings,
  Point
} from 'devextreme-react/chart'
import 'react-moment'
import ProcAbilityToolTip from './ProcAbilityToolTip'
const ProcAbility = (props) => {
  const crosshairFormat = {
    type: 'fixedPoint',
    precision: 2
  }
  const customizePoint = (arg) => {
    if (arg.value > 0.67 && arg.value < 1.0) {
      return { color: '#fd2121', hoverStyle: { color: '#fd2121' } }
    } else if (arg.value > 1.0 && arg.value < 1.33) {
      return { color: '#fd9621', hoverStyle: { color: '#fd9621' } }
    } else if (arg.value > 1.33 && arg.value < 1.67) {
      return { color: '#004d0e', hoverStyle: { color: '#004d0e' } }
    } else if (arg.value > 1.67) {
      return { color: '#1c14ff', hoverStyle: { color: '#1c14ff' } }
    }
  }
  return (
    //  X_UCL  X_CL  X_LCL Green
    // USL LSL CL Led Line
    <div className="dx-card">
      <Chart
        className="chart"
        id="chart"
        dataSource={props.ProcAbilityXYdata}
        customizePoint={customizePoint}
      >
        <CommonSeriesSettings argumentField="complaint" hoverMode="includePoints">
          <Point hoverMode="allArgumentPoints" />
        </CommonSeriesSettings>
        <Size height={850} />
        <Series name="cp" argumentField="inspTime" valueField="cp" type="line" color="#679EC5" />
        <Series name="cpk" argumentField="inspTime" valueField="cpk" type="line" color="#e7d19a" />

        <ArgumentAxis valueMarginsEnabled={false}>
          <Label format="shortDateShortTime" />
        </ArgumentAxis>
        <ValueAxis maxValueMargin={0.01}>
          <ConstantLine width={2} value={0.67} color="#fd2121" dashStyle="dash">
            <Label text={0.67}>
              <Font color="#fd2121"></Font>
            </Label>
          </ConstantLine>
          <ConstantLine width={2} value={1.0} color="#fd9621" dashStyle="dash">
            <Label text={1.0}>
              <Font color="#fd9621"></Font>
            </Label>
          </ConstantLine>
          <ConstantLine width={2} value={1.33} color="#004d0e" dashStyle="dash">
            <Label text={1.33}>
              <Font color="#004d0e"></Font>
            </Label>
          </ConstantLine>
          <ConstantLine width={2} value={1.67} color="#1c14ff" dashStyle="dash">
            <Label text={1.67}>
              <Font color="#1c14ff"></Font>
            </Label>
          </ConstantLine>
        </ValueAxis>

        <Crosshair enabled={true}>
          <HorizontalLine visible={true}></HorizontalLine>
          <Label visible={true} format={crosshairFormat} />
          <VerticalLine color="#f5cc5c">
            <Label visible={true} backgroundColor="#f5cc5c" format="shortDateShortTime">
              <Font color="#fff" size={12} />
            </Label>
          </VerticalLine>
        </Crosshair>
        <Tooltip enabled={true} shared={true} contentRender={ProcAbilityToolTip} />
        <ScrollBar visible={true} />
        <ZoomAndPan argumentAxis="both" />
        <Legend visible={false} />
        <Export enabled={true} />
      </Chart>
    </div>
  )
}

export default ProcAbility
