import React, { useState } from 'react'
import './RChart.scss'
import {
  Chart,
  Size,
  Series,
  Legend,
  ValueAxis,
  VisualRange,
  Label,
  ConstantLine,
  Export,
  Tooltip,
  ZoomAndPan,
  ScrollBar,
  Crosshair,
  Font,
  ArgumentAxis,
  HorizontalLine,
  VerticalLine,
  HorizontalLineLabel
} from 'devextreme-react/chart'
import 'react-moment'
import XVarChartTooltip from './XVarChartTooltip'

const RChart = (props) => {
  const [highAverage, setHighAverage] = useState(props.AnalysisTargetData.rUcl)
  const [lowAverage, setLowAverage] = useState(props.AnalysisTargetData.rLcl)

  const customizePoint = (arg) => {
    if (arg.value > highAverage) {
      return { color: '#ff7c7c', hoverStyle: { color: '#ff7c7c' } }
    } else if (arg.value < lowAverage) {
      return { color: '#8c8cff', hoverStyle: { color: '#8c8cff' } }
    }
  }
  const customizeLabel = (arg) => {
    if (arg.value > highAverage) {
      return {
        visible: true,
        backgroundColor: '#ff7c7c',
        customizeText: function (e) {
          return `${e.valueText}&#176`
        }
      }
    }
  }
  const customizeText = (arg) => {
    return `${arg.valueText}&#176`
  }
  const rUclText = '[R_Ucl] : ' + props.AnalysisTargetData.rUcl
  const rClText = '[R_Cl] : ' + props.AnalysisTargetData.rCl
  const rLclText = '[R_Lcl] : ' + props.AnalysisTargetData.rLcl
  return (
    <div className="dx-card">
      <Chart
        className="chart"
        id="chart"
        dataSource={props.RChartXYdata}
        // customizePoint={customizePoint}
        // customizeLabel={customizeLabel}
      >
        <Size height={850} />
        <Series
          name="rVal"
          argumentField="inspTime"
          valueField="rVal"
          type="line"
          color="#679EC5"
        />
        <ArgumentAxis valueMarginsEnabled={false}>
          <Label format="shortDateShortTime" />
        </ArgumentAxis>
        <ValueAxis maxValueMargin={0.01}>
          <VisualRange startValue={lowAverage} endValue={highAverage} />
          <ConstantLine
            width={2}
            value={props.AnalysisTargetData.rUcl}
            color="#004202"
            dashStyle="dash"
          >
            <Label text={rUclText}>
              <Font color="#004202"></Font>
            </Label>
          </ConstantLine>
          <ConstantLine
            width={2}
            value={props.AnalysisTargetData.rCl}
            color="#004202"
            dashStyle="dash"
          >
            <Label text={rClText}>
              <Font color="#004202"></Font>
            </Label>
          </ConstantLine>
          <ConstantLine
            width={2}
            value={props.AnalysisTargetData.rLcl}
            color="#004202"
            dashStyle="dash"
          >
            <Label text={rLclText}>
              <Font color="#004202"></Font>
            </Label>
          </ConstantLine>
        </ValueAxis>
        <Crosshair enabled={true} color="#949494" width={3} dashStyle="dot">
          <HorizontalLine color="#637afd">
            <HorizontalLineLabel visible={true} backgroundColor="#637afd" format="decimal">
              <Font color="#fff" size={12} />
            </HorizontalLineLabel>
          </HorizontalLine>
          <VerticalLine color="#f5cc5c">
            <Label visible={true} backgroundColor="#f5cc5c" format="shortDateShortTime">
              <Font color="#fff" size={12} />
            </Label>
          </VerticalLine>
        </Crosshair>
        <Tooltip enabled={true} shared={true} contentRender={XVarChartTooltip} />
        <ScrollBar visible={true} />
        <ZoomAndPan argumentAxis="both" />
        <Legend visible={false} />
        <Export enabled={true} />
      </Chart>
    </div>
  )
}

export default RChart
