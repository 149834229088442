import React, { useEffect, useState } from 'react'
import './QualityStatisticsAnaysisGrid.scss'
import DataGrid, { Column, Scrolling, Export } from 'devextreme-react/data-grid'
import * as _ from 'lodash'

const QualityStatisticsAnaysisGrid = (props) => {
  const customizeText = (cellInfo) => {
    return `${_.round(cellInfo.value, 3)}`
  }
  const [QualityStatisticsAnaysisList, setQualityStatisticsAnaysisList] = useState([])
  useEffect(() => {
    setQualityStatisticsAnaysisList(props.historyArray)
  }, [])

  return (
    <React.Fragment>
      <DataGrid
        id="gridContainer"
        dataSource={props.historyArray}
        showBorders={true}
        focusedRowEnabled={true}
        defaultFocusedRowIndex={0}
        allowColumnResizing={true}
        columnResizingMode={'nextColumn'}
        keyExpr="testSeq"
        columnAutoWidth={true}
        height={843}
      >
        <Export enabled={true}></Export>
        <Scrolling mode="infinite" />
        <Column dataField="inspTime" dataType="date" caption="측정일시" />
        <Column dataField="userName" caption="측정자" />
        <Column dataField="x1" caption="X1" hidingPriority={0} customizeText={customizeText} />
        <Column dataField="x2" caption="X2" hidingPriority={1} customizeText={customizeText} />
        <Column dataField="x3" caption="X3" hidingPriority={2} customizeText={customizeText} />
        <Column dataField="minVal" caption="MIN" hidingPriority={3} customizeText={customizeText} />
        <Column dataField="maxVal" caption="MAX" hidingPriority={4} customizeText={customizeText} />
        <Column
          dataField="stdevVal"
          caption="STD"
          hidingPriority={5}
          customizeText={customizeText}
        />
        <Column dataField="cp" caption="Cp" hidingPriority={6} customizeText={customizeText} />
        <Column dataField="cpk" caption="Cpk" hidingPriority={7} customizeText={customizeText} />
        <Column
          dataField="xVarVal"
          caption="X-VAR"
          hidingPriority={8}
          customizeText={customizeText}
        />
        <Column dataField="rVal" caption="R-VAL" hidingPriority={9} customizeText={customizeText} />
      </DataGrid>
    </React.Fragment>
  )
}
export default QualityStatisticsAnaysisGrid
