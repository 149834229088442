import { call, put, select, takeLatest } from 'redux-saga/effects';
import { getProductList } from '../../api';
import { unsplashAction, unsplashSelector } from './reducers';

export function* productListLoad() {
    const { loadSuccess, loadFail } = unsplashAction;

    try {
        const productList = yield call(getProductList);

        yield put(loadSuccess({
            list: productList.list
        }));
    } catch (err) {
        yield put(loadFail(err));
    }
}

export function* watchUnsplash() {
    const { load, loadMore } = unsplashAction;
  
    yield takeLatest(load, productListLoad);
    yield takeLatest(loadMore, productListLoad);
}