/**
먼저 saga.js에는 보통 2개의 generator 함수가 선언되는데,

하나는 보통 watch~라는 이름으로 생성되는 함수이다.

출처: https://im-developer.tistory.com/195 [Code Playground] */

import { call, put, select, takeLatest } from 'redux-saga/effects'
import { getQualityStatisticsAnaysisList, getCustomLookUp, getMchLookUp } from '../../api'
import { qualityStatisticsAnaysisAction, qualityStatisticsAnaysisSelector } from './slice'

export function* searchParamListLoad() {
  const { searchParamLoadSuccess, searchParamLoadFail } = qualityStatisticsAnaysisAction

  try {
    const param = yield select(qualityStatisticsAnaysisSelector.lookUpParam)
    let selectSearchParamData = null
    if (param.attSeq == null) {
      selectSearchParamData = yield call(getCustomLookUp, param)
    } else {
      selectSearchParamData = yield call(getMchLookUp, param)
    }

    yield put(
      searchParamLoadSuccess({
        result: selectSearchParamData
      })
    )
  } catch (err) {
    yield put(searchParamLoadFail(err))
  }
}

export function* qualityStatisticsAnaysisListLoad() {
  /** 2.
      이 함수는 getQualityStatisticsAnaysisList 라는 api 함수를 call이라는 saga effect 함수에 넣은 후 yield 시키고 있다.
      그러면 saga 미들웨어에서 알아서 getQualityStatisticsAnaysisList라는 비동기 액션을 실행시킨 후 그 결과물을 qualityStatisticsAnaysisList라는 변수에 담아준다.

      그 후 put이라는 effect 함수에 loadSuccess 액션 객체를 넣고 있는데,
      이 때 위에서 fetch 결과물로 받은 qualityStatisticsAnaysisList 배열을 인자로 넣어주고 있다.
      이 인자는 action 객체의 payload 속성으로 들어가며 위 slice.js에서 생성된 reducer에 의해 새로운 상태값으로 업데이트된다.

      try catch문을 사용하여 만약에 에러가 catch된다면
      loadFail이라는 액션 객체를 생성하여 put 이펙트 함수에 넣어주면 된다.

      너무나도 간단하게 비동기 로직이 정리되며, 마치 비동기가 아닌 동기 코드처럼 보여진다(!)

      이제 이렇게 생성된 saga와 slice들을 연결을 해주어야 동작하게 된다.
   */
  /**
   * yield 키워드는 제너레이터 함수 (function* 또는 레거시 generator 함수)를 중지하거나 재개하는데 사용됩니다.
   */
  const { loadSuccess, loadFail } = qualityStatisticsAnaysisAction

  try {
    const param = yield select(qualityStatisticsAnaysisSelector.searchParam)
    const qualityStatisticsAnaysisList = yield call(getQualityStatisticsAnaysisList, param)

    yield put(
      loadSuccess({
        result: qualityStatisticsAnaysisList
      })
    )
  } catch (err) {
    yield put(loadFail(err))
  }
}

export function* watchQualityStatisticsAnaysis() {
  /** 1.
      watchQualityStatisticsAnaysis함수에서는 takeLatest라는 함수 안에 load 액션 함수를 인자로 넣고,
      qualityStatisticsAnaysisListLoad 함수를 두 번째 인자로 넣는다.
      그러면 이제 load 액션이 dispatch되는 순간 saga에서 그 액션을 take한 후,
      qualityStatisticsAnaysisListLoad generator 함수를 실행시킨다
    */
  const { searchParamLoad, load, loadMore } = qualityStatisticsAnaysisAction

  yield takeLatest(searchParamLoad, searchParamListLoad)
  yield takeLatest(load, qualityStatisticsAnaysisListLoad)
  yield takeLatest(loadMore, qualityStatisticsAnaysisListLoad)
}
