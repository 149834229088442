import { withNavigationWatcher } from './contexts/navigation'
import {
  HomePage,
  DisplayDataPage,
  //ProfilePage,
  ViewNamePage,
  QualityStatisticsAnaysis,
  FileUpload
} from './pages'

const routes = [
  {
    path: '/display-data',
    component: DisplayDataPage
  },
  // {
  //   path: '/profile',
  //   component: ProfilePage
  // },
  {
    path: '/home',
    component: HomePage
  },
  {
    path: '/view-name',
    component: ViewNamePage
  },
  {
    path: '/FileUpload',
    component: FileUpload
  },
  {
    path: '/QualityStatisticsAnaysis',
    component: QualityStatisticsAnaysis
  }
]

export default routes.map((route) => {
  return {
    ...route,
    component: withNavigationWatcher(route.component)
  }
})
