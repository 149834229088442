import React, { useEffect, useState } from 'react'
import FileUploader from 'devextreme-react/file-uploader'
import Button from 'devextreme-react/button'
import TextBox from 'devextreme-react/text-box'
import notify from 'devextreme/ui/notify'
import axios from 'axios'
const FileUpload = () => {
  const [selectedFiles, setSelectedFiles] = useState(null)

  const upload = (e) => {
    fileUpload(selectedFiles).then((response) => {
      console.log(response)
      alert(response.data[0].fileName + '이 다운로드 됫습니다.')
    })
  }
  const fileUpload = (file) => {
    console.log(file)
    const url = '/api/resourceFile/uploadMultiFiles'
    const formData = new FormData()
    formData.append('files', file)
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    return axios.post(url, formData, config)
  }
  const onSelectedFilesChanged = (e) => {
    setSelectedFiles(e.value)
  }
  return (
    <React.Fragment>
      <h2 className={'content-block dx-card responsive-paddings'}>자료 업로드</h2>
      <div className={'content-block dx-card responsive-paddings'}>
        <div className="fileuploader-container">
          <FileUploader
            name="files"
            uploadUrl="/api/resourceFile/uploadMultiFiles"
            selectButtonText="파일 선택"
            labelText=""
            accept="*"
            uploadMode="instantly"
            multiple={true}
            onValueChanged={onSelectedFilesChanged}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default FileUpload
