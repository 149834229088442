import React from 'react'
import './Grid.scss'
import DataGrid, {
  Column,
  Scrolling,
  FilterRow,
  Editing,
  Lookup,
  Sorting,
  Selection
} from 'devextreme-react/data-grid'

const ProductList = (props) => {
  return (
    <React.Fragment>
      <h2 className={'content-block'}>MasterProduct</h2>
      <DataGrid
        className={'dx-card wide-card'}
        dataSource={props.list}
        showBorders={true}
        loadPanel={{
          showIndicator: true,
          enabled: true
        }}
        focusedRowEnabled={true}
        defaultFocusedRowIndex={0}
        allowColumnResizing={true}
        columnResizingMode={'nextColumn'}
        columnMinWidth={50}
        columnAutoWidth={true}
        keyExpr="itemId"
        elementAttr={{
          id: 'gridContainer'
        }}
        selectedRowKeys={props.selectedRowKeys}
        onSelectionChanged={props.selectionChanged}
      >
        {/* <Paging defaultPageSize={10} />
          <Pager showPageSizeSelector={true} showInfo={true} /> */}
        <Selection mode="multiple" />
        <Editing mode="cell" allowUpdating={false} allowAdding={true} />
        <Sorting mode="none" />
        <Scrolling mode="infinite" />
        <FilterRow visible={true} showAllText={'전체'} />

        <Column dataField={'itemId'} caption="품목코드" width={90} hidingPriority={2} />
        <Column dataField={'itemCode'} caption={'제품코드'} hidingPriority={1} />
        <Column dataField={'itemName'} caption={'제품명'} hidingPriority={1} />
        <Column dataField={'prdtType'} caption={'제품유형'} hidingPriority={5}>
          <Lookup dataSource={itemType} valueExpr={'value'} displayExpr={'name'} />
        </Column>
        <Column dataField={'prdtCtg'} caption={'제품카테고리'} hidingPriority={5}>
          <Lookup dataSource={itemCtg} valueExpr={'value'} displayExpr={'name'} />
        </Column>
        <Column dataField={'prdtGroup'} caption={'제품그룹'} hidingPriority={5}>
          <Lookup dataSource={itemGroup} valueExpr={'value'} displayExpr={'name'} />
        </Column>
        <Column dataField={'heatSpec'} caption={'열처리사양'} hidingPriority={3}>
          <Lookup dataSource={heatSpec} valueExpr={'value'} displayExpr={'name'} />
        </Column>
        <Column dataField={'surpaceSpec'} caption={'표면처리사양'} hidingPriority={3}>
          <Lookup dataSource={surpaceSpec} valueExpr={'value'} displayExpr={'name'} />
        </Column>
        <Column dataField={'coatingSpec'} caption={'코팅사양'} hidingPriority={3}>
          <Lookup dataSource={coatingSpec} valueExpr={'value'} displayExpr={'name'} />
        </Column>
        <Column dataField={'batchSize'} caption={'배치기준'} hidingPriority={3} />
        <Column dataField={'batchUnit'} caption={'배치단위'} hidingPriority={3}>
          <Lookup dataSource={batchUnit} valueExpr={'value'} displayExpr={'name'} />
        </Column>
        <Column dataField={'lotSize'} caption={'로트사이즈'} hidingPriority={4} />
        <Column caption={'로트단위'} hidingPriority={4}>
          <Lookup dataSource={lotUnit} valueExpr={'value'} displayExpr={'name'} />
        </Column>
        <Column dataField={'invType'} caption={'로트관리여부'}>
          <Lookup dataSource={lotYN} valueExpr={'value'} displayExpr={'name'} />
        </Column>
      </DataGrid>
    </React.Fragment>
  )
}

const itemType = [
  { name: '없음', value: null },
  { name: '반제품', value: 'ITM001002' },
  { name: '완제품', value: 'ITM001001' }
]

const itemCtg = [
  { name: '없음', value: null },
  { name: '리벳', value: 'CTG001001' },
  { name: '샤프트', value: 'CTG001002' },
  { name: '볼트', value: 'CTG001003' }
]

const itemGroup = [{ name: '없음', value: 'GRP001000' }]

const heatSpec = [
  { name: '없음', value: null },
  { name: '조질열처리', value: 'SPF001000' },
  { name: '노멀라이징', value: 'SPF001001' },
  { name: '고주파', value: 'SPF001002' }
]

const surpaceSpec = [
  { name: '없음', value: null },
  { name: '도금없음', value: 'SPF002000' },
  { name: '아연도금', value: 'SPF002001' },
  { name: '지오매트', value: 'SPF002002' }
]

const coatingSpec = [
  { name: '없음', value: null },
  { name: '아연코팅', value: 'SPF003000' }
]

const batchUnit = [
  { name: '', value: null },
  { name: 'LOT', value: 'UNT002001' },
  { name: 'EA', value: 'UNT002002' },
  { name: 'SET', value: 'UNT002003' },
  { name: 'BOX', value: 'UNT002004' },
  { name: 'PACK', value: 'UNT002005' },
  { name: 'Kg', value: 'UNT002006' }
]

const lotUnit = [
  { name: '', value: null },
  { name: 'EA', value: 'UNT018001' },
  { name: 'Kg', value: 'UNT018002' }
]

const lotYN = [
  { name: 'Y', value: 0 },
  { name: 'N', value: 1 }
]

export default ProductList
