/**
사가 미들웨어의 handleImageLoad 가 실행되어 비동기 fetch가 일어나고

그 결과물이 업데이트되어 컴포넌트가 다시 리렌더가 된다.

업데이트된 상태값들은 selector를 이용해 꺼내 쓴다.

출처: https://im-developer.tistory.com/195 [Code Playground] 
 */
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { qualityStatisticsAnaysisAction, qualityStatisticsAnaysisSelector } from './slice'
import './QualityStatisticsAnaysis.scss'
import AnalysisTarget from './../../components/QualityStatisticsAnaysis/AnalysisTarget'
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box'
import QualityStatisticsAnaysisGrid from '../../components/QualityStatisticsAnaysis/QualityStatisticsAnaysisGrid'
import TabPanel from 'devextreme-react/tab-panel'
import XVarChart from './../../components/QualityStatisticsAnaysis/Chart/XVarChart'
import RChart from './../../components/QualityStatisticsAnaysis/Chart/RChart'
import ProcDistribution from './../../components/QualityStatisticsAnaysis/Chart/ProcDistribution'
import ProcAbility from './../../components/QualityStatisticsAnaysis/Chart/ProcAbility'
import SearchParam from './../../components/QualityStatisticsAnaysis/SearchParam'
import Test from '../../components/QualityStatisticsAnaysis/Test'
const screen = (width) => {
  return width < 700 ? 'sm' : 'lg'
}
const QualityStatisticsAnaysis = () => {
  const dispatch = useDispatch()

  const {
    isLoading,
    list,
    error,
    searchParamData,
    AnalysisTargetData,
    historyArray,
    xVarChartXYdata,
    RChartXYdata,
    histogramArray,
    lineArray,
    ProcDistributionXYdata,
    ProcAbilityXYdata
  } = useSelector(qualityStatisticsAnaysisSelector.all)

  // useEffect(() => {
  //   dispatch(qualityStatisticsAnaysisAction.load())
  // }, [])

  return (
    <React.Fragment>
      <ResponsiveBox singleColumnScreen="sm" screenByWidth={screen}>
        <Row ratio={1} screen="xs"></Row>
        <Row ratio={1} screen="xs"></Row>
        <Row ratio={1} screen="xs"></Row>
        <Col ratio={1} screen="xs"></Col>

        <Row ratio={1} screen="lg"></Row>
        <Row ratio={1} screen="lg"></Row>
        <Col ratio={1} screen="lg"></Col>
        <Col ratio={2} screen="lg"></Col>
        <Item>
          <Location row={0} col={0} colspan={2} screen="lg"></Location>
          <Location row={0} col={0} screen="sm"></Location>
          <div className="component ">
            <h2 className="title ">품질통계분석</h2>
            <SearchParam></SearchParam>
            {/* <SearchParam searchParamData={searchParamData}></SearchParam> */}
            {/* <Test searchParamData={searchParamData}></Test> */}
          </div>
        </Item>
        <Item>
          <Location row={1} col={0} screen="lg"></Location>
          <Location row={1} col={0} screen="sm"></Location>
          <div className="component">
            <AnalysisTarget AnalysisTargetData={AnalysisTargetData} />
          </div>
        </Item>
        <Item>
          <Location row={1} col={1} screen="lg"></Location>
          <Location row={2} col={0} screen="sm"></Location>
          <div>
            <div className="component">
              <h2>측정이력</h2>
              <QualityStatisticsAnaysisGrid historyArray={historyArray} />
            </div>
            <div className="component">
              <h2>X Var Chart</h2>
              <XVarChart
                AnalysisTargetData={AnalysisTargetData}
                xVarChartXYdata={xVarChartXYdata}
              />
            </div>
            <div className="component">
              <h2>R Chart</h2>
              <RChart AnalysisTargetData={AnalysisTargetData} RChartXYdata={RChartXYdata} />
            </div>
            <div className="component">
              <h2>공정분포도</h2>
              <ProcDistribution
                AnalysisTargetData={AnalysisTargetData}
                xVarChartXYdata={xVarChartXYdata}
                histogramArray={histogramArray}
                lineArray={lineArray}
                ProcDistributionXYdata={ProcDistributionXYdata}
              />
            </div>
            <div className="component">
              <h2>공정능력추이</h2>
              <ProcAbility ProcAbilityXYdata={ProcAbilityXYdata} />
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  )

  // return (
  //   <React.Fragment>
  //     <ResponsiveBox singleColumnScreen="sm" screenByWidth={screen}>
  //       <Row ratio={1} screen="xs"></Row>
  //       <Row ratio={1}></Row>
  //       <Col ratio={1}></Col>
  //       <Col ratio={2} screen="lg"></Col>
  //       <Item>
  //         <Location row={0} col={0} screen="lg"></Location>
  //         <Location row={0} col={0} screen="sm"></Location>
  //         <div className="component ">
  //           <h2 className="title">품질통계분석</h2>
  //           <AnalysisTarget AnalysisTargetData={AnalysisTargetData} />
  //         </div>
  //       </Item>
  //       <Item>
  //         <Location row={0} col={1} screen="lg"></Location>
  //         <Location row={1} col={1} screen="sm"></Location>
  //         <div className="component">
  //           <TabPanel loop={false} animationEnabled={true} swipeEnabled={true}>
  //             <Item title="측정이력">
  //               <QualityStatisticsAnaysisGrid historyArray={historyArray} />
  //             </Item>
  //             <Item title="X Var Chart">
  //               <XVarChart
  //                 AnalysisTargetData={AnalysisTargetData}
  //                 xVarChartXYdata={xVarChartXYdata}
  //               />
  //             </Item>
  //             <Item title="R Chart">
  //               <RChart AnalysisTargetData={AnalysisTargetData} RChartXYdata={RChartXYdata} />
  //             </Item>
  //             <Item title="공정분포도">
  //               <ProcDistribution
  //                 AnalysisTargetData={AnalysisTargetData}
  //                 xVarChartXYdata={xVarChartXYdata}
  //                 histogramArray={histogramArray}
  //                 lineArray={lineArray}
  //                 ProcDistributionXYdata={ProcDistributionXYdata}
  //               />
  //             </Item>
  //             <Item title="공정능력추이">
  //               <ProcAbility ProcAbilityXYdata={ProcAbilityXYdata} />
  //             </Item>
  //           </TabPanel>
  //         </div>
  //       </Item>
  //     </ResponsiveBox>
  //   </React.Fragment>
  // )
}

export default QualityStatisticsAnaysis
