import React from 'react'
import Form, { Label, SimpleItem } from 'devextreme-react/form'
import 'devextreme-react/text-area'
import './AnalysisTarget.scss'
const AnalysisTarget = (props) => {
  const colCountByScreen = {
    sm: 2,
    md: 2
  }
  function screenByWidth(width) {
    return width < 720 ? 'sm' : 'md'
  }
  return (
    <div className={'AnalysisTargetConponent'}>
      <div className={'dx-card paddings'}>
        <Form
          formData={props.AnalysisTargetData}
          labelLocation="top"
          colCount="auto"
          showColonAfterLabel={true}
          readOnly={true}
          colCountByScreen={colCountByScreen}
          screenByWidth={screenByWidth}
        >
          <SimpleItem dataField="itemCode">
            <Label text="제품코드"></Label>
          </SimpleItem>
          <SimpleItem dataField="itemName">
            <Label text="제품명"></Label>
          </SimpleItem>
          <SimpleItem dataField="procCode">
            <Label text="공정코드"></Label>
          </SimpleItem>
          <SimpleItem dataField="procName">
            <Label text="공정명"></Label>
          </SimpleItem>
          <SimpleItem dataField="mchCode">
            <Label text="설비코드"></Label>
          </SimpleItem>
          <SimpleItem dataField="mchName">
            <Label text="설비명"></Label>
          </SimpleItem>
          <SimpleItem dataField="attName">
            <Label text="측정항목"></Label>
          </SimpleItem>
          <SimpleItem dataField="msdStd">
            <Label text="측정기준"></Label>
          </SimpleItem>
          <SimpleItem dataField="msdTypeName">
            <Label text="측정유형"></Label>
          </SimpleItem>
          <SimpleItem dataField="cycle">
            <Label text="측정주기"></Label>
          </SimpleItem>
          <SimpleItem dataField="inspCnt">
            <Label text="검사횟수"></Label>
          </SimpleItem>
          <SimpleItem dataField="sampleCnt">
            <Label text="샘플수"></Label>
          </SimpleItem>
          <SimpleItem dataField="avgVal">
            <Label text="평균"></Label>
          </SimpleItem>
          <SimpleItem dataField="stdevVal">
            <Label text="표준편차"></Label>
          </SimpleItem>
          <SimpleItem dataField="minVal">
            <Label text="최소값"></Label>
          </SimpleItem>
          <SimpleItem dataField="maxVal">
            <Label text="최대값"></Label>
          </SimpleItem>
          <SimpleItem dataField="pp">
            <Label text="Pp"></Label>
          </SimpleItem>
          <SimpleItem dataField="ppk">
            <Label text="Ppk"></Label>
          </SimpleItem>
        </Form>
      </div>
    </div>
  )
}

export default AnalysisTarget
