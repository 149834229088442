import * as _ from 'lodash'
/* 날짜 format */
const localeString = (date) => {
  return new Date(date).toLocaleString() // ex) "2020. 6. 6. 오전 1:40:29"
}
const dateFormatSring = (date, formatEx) => {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  if (_.isEqual(formatEx, 'yyyy-mm-dd')) {
    return year + '-' + month + '-' + day
  }
}
const jsonString = (date) => {
  return date.toJSON()
}
export const dateFormat = {
  localeString: localeString,
  dateFormatSring: dateFormatSring,
  jsonString: jsonString
}
