export const navigation = [
  {
    text: '자료실',
    path: '/home',
    icon: 'home'
  }
  //{ text: '자료실 업로드', path: '/FileUpload', icon: 'folder' }
  // {
  //   text: '품질통계분석',
  //   path: '/QualityStatisticsAnaysis',
  //   icon: 'folder'
  // }
]
