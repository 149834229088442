import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { unsplashAction, unsplashSelector } from './reducers'
import './view-name.scss'
import MasterProductGrid from './../../components/MasterProduct/Grid'

const ProductList = () => {
  const dispatch = useDispatch()

  const { isLoading, list, error } = useSelector(unsplashSelector.all)

  const [selectedItemKeys, setSelectedItemKeys] = useState([])

  useEffect(() => {
    dispatch(unsplashAction.load())
  }, [])

  const selectionChanged = (data) => {
    setSelectedItemKeys(data.selectedRowKeys)
  }

  return (
    <React.Fragment>
      <MasterProductGrid
        list={list}
        selectedItemKeys={selectedItemKeys}
        selectionChanged={selectionChanged}
      ></MasterProductGrid>
    </React.Fragment>
  )
}

export default ProductList
