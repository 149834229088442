/**  QualityStatisticsAnaysis 라는 이름으로 createSlice를 이용해 slice를 생성하고,

그 slice 객체에 자동으로 생성된 name과 reducer, actions 등을 export해주고 있다.

출처: https://im-developer.tistory.com/195 [Code Playground]*/

import { createSelector, createSlice } from '@reduxjs/toolkit'
import * as _ from 'lodash'
import * as CommChart from '../../common/CommChart'

const name = 'QualityStatisticsAnaysis'

export const initialState = {
  success: false,
  code: 0,
  msg: '',
  rowSize: 0,
  isLoading: false,
  error: null,
  result: {},
  fieldName: [],
  fieldType: [],

  list: {}, // QualityStaticsAnaysis 전체 데이터
  AnalysisTargetData: {}, // AnalysisTargetData Component 데이터
  historyArray: [], // chart,grid 데이터
  histogramArray: [], // chart,grid 데이터
  lineArray: [], // chart,grid 데이터

  /* X Var Chart */
  XvarChartXYdata: [],
  XvarChartData: {},
  ProcDistributionXYdata: [],
  ProcAbilityXYdata: [],

  /** SearchParam */
  // 조회조건 api param
  lookUpParam: {
    inspType: 'TPS010003',
    itemId: null,
    procCode: null,
    inspRev: null,
    attSeq: null
  },
  // 조회조건 리스트값
  searchParamData: {
    itemIdField: [], // 제품 리스트 itemIdField[{itemId , itemCode}]
    procField: [], // 공정 리스트 procField[{"procCode", "procName"}]
    inspRevField: [], // 개정순번 리스트  inspRevField []
    attSeqField: [], // 검사항목 리스트 attSeqField [{attSeq,attName}]
    mchIdField: [] // 설비 리스트 mchIdField[{mchId,mchName}]
  },
  // 그리드 및 차트 정보 조회 param
  searchParam: {
    inspType: 'TPS010003',
    attSeq: null,
    itemId: null,
    mchId: null,
    procCode: null,
    endDate: null,
    inspRev: null,
    startDate: null
  }
}

const reducers = {
  searchParamLoad: (state, payload) => {
    state.isLoading = true
    state.lookUpParam = { ...payload.payload }
  },
  searchParamLoadSuccess: (state, { payload: { result } }) => {
    // 제품 selectBox
    if (!_.isEmpty(state.lookUpParam.inspType) && _.isEmpty(state.lookUpParam.itemId)) {
      let filterData = []
      state.searchParamData.procField = []
      for (const [key, value] of Object.entries(result.list)) {
        filterData.push(_.pick(value, 'itemId', 'itemCode', 'itemName'))
      }
      state.searchParamData.itemIdField = _.uniqWith(filterData, _.isEqual)
      state.searchParamData.procField = []
      state.searchParamData.inspRevField = []
      state.searchParamData.attSeqField = []
      state.searchParamData.mchIdField = []
    }

    // 공정 select Box
    if (!_.isEmpty(state.lookUpParam.itemId) && _.isEmpty(state.lookUpParam.procCode)) {
      let filterData = []
      for (const [key, value] of Object.entries(result.list)) {
        filterData.push(_.pick(value, 'procCode', 'procName'))
      }
      state.searchParamData.procField = _.uniqWith(filterData, _.isEqual)
      state.searchParamData.inspRevField = []
      state.searchParamData.attSeqField = []
      state.searchParamData.mchIdField = []
    }

    // 개정순번 selectBox
    if (!_.isEmpty(state.lookUpParam.procCode) && _.isNull(state.lookUpParam.inspRev)) {
      let filterData = []
      let attFilterData = []
      for (const [key, value] of Object.entries(result.list)) {
        filterData.push(
          _.pick(value, 'itemId', 'itemCode', 'itemName', 'procCode', 'procName', 'inspRev')
        )
        attFilterData.push(
          _.pick(
            value,
            'itemId',
            'itemCode',
            'itemName',
            'procCode',
            'procName',
            'attSeq',
            'attName'
          )
        )
      }
      state.searchParamData.inspRevField = _.uniqWith(filterData, _.isEqual)
      state.searchParamData.attSeqField = _.uniqWith(attFilterData, _.isEqual)
    }
    // 설비 selectBox
    if (
      !_.isEmpty(state.lookUpParam.procCode) &&
      !_.isNull(state.lookUpParam.inspRev) &&
      !_.isNull(state.lookUpParam.attSeq)
    ) {
      let filterData = []
      for (const [key, value] of Object.entries(result.list)) {
        filterData.push(_.pick(value, 'mchId', 'mchCode', 'mchName'))
      }
      state.searchParamData.mchIdField = _.uniqWith(filterData, _.isEqual)
    }
  },
  searchParamLoadFail: (state, { payload: error }) => {
    state.error = error
  },
  load: (state, payload) => {
    state.isLoading = true
    state.searchParam = { ...payload.payload }
  },
  loadMore: (state) => {
    return state
  },
  loadSuccess: (state, { payload: { result } }) => {
    state.isLoading = false
    state.code = result.success
    state.fieldName = result.fieldName
    state.fieldType = result.fieldType
    state.msg = result.msg
    state.rowSize = result.rowSize
    state.success = result.success

    /** 컴포넌트 별 데이터 분산 */
    for (const [key, value] of Object.entries(result.list[0])) {
      switch (key) {
        case 'qualityStatisticsAnaysisHistoryList':
          state.XvarChartXYdata = [...CommChart.XYdataSelect(value, ['inspTime', 'xVarVal'])] // [X Var Chart] : x,y 축 데이터 select
          state.RChartXYdata = [...CommChart.XYdataSelect(value, ['inspTime', 'rVal'])] // [R Chart] : x,y 축 데이터 select
          state.ProcAbilityXYdata = [...CommChart.XYdataSelect(value, ['inspTime', 'cp', 'cpk'])] // [공정능력추이 Chart] : x,y 축 데이터 select
          state.historyArray = [...value]
          break
        case 'qualityStatisticsAnaysisDistributionHistogramList':
          state.histogramArray = [...value]
          break
        case 'qualityStatisticsAnaysisDistributionLineList':
          state.lineArray = [...value]
          break
        default:
          state.AnalysisTargetData[key] = value
          break
      }
    }
    state.ProcDistributionXYdata = _.union(state.histogramArray, state.lineArray, 'x') // [공정분포도 Chart] : x,y 축 데이터 select
  },
  loadFail: (state, { payload: error }) => {
    state.isLoading = false
    state.error = error
  }
}

const slice = createSlice({
  name,
  initialState,
  reducers
})
const selectSearchParam = createSelector(
  (state) => state.searchParam,
  (searchParam) => searchParam
)
const selectLookUpParam = createSelector(
  (state) => state.lookUpParam,
  (lookUpParam) => lookUpParam
)
const selectLoadingState = createSelector(
  (state) => state.isLoading,
  (isLoading) => isLoading
)

const selectSearchParamData = createSelector(
  (state) => state.searchParamData,
  (searchParamData) => searchParamData
)
const selectQualityStatisticsAnaysisList = createSelector(
  (state) => state.AnalysisTargetData,
  (AnalysisTargetData) => AnalysisTargetData
)

const selectHistoryArray = createSelector(
  (state) => state.historyArray,
  (historyArray) => historyArray
)

const selectError = createSelector(
  (state) => state.error,
  (error) => error
)

const selectHistogramArray = createSelector(
  (state) => state.histogramArray,
  (histogramArray) => histogramArray
)
const selectLineArray = createSelector(
  (state) => state.lineArray,
  (lineArray) => lineArray
)
const selectXvarChartXYData = createSelector(
  (state) => state.XvarChartXYdata,
  (xVarChartXYdata) => xVarChartXYdata
)
const selectRChartXYData = createSelector(
  (state) => state.RChartXYdata,
  (RChartXYdata) => RChartXYdata
)
const selectProcDistributionXYdata = createSelector(
  (state) => state.ProcDistributionXYdata,
  (ProcDistributionXYdata) => ProcDistributionXYdata
)
const selectProcAbilityXYdata = createSelector(
  (state) => state.ProcAbilityXYdata,
  (ProcAbilityXYdata) => ProcAbilityXYdata
)
const selectAllState = createSelector(
  // 순서 주의
  selectSearchParam,
  selectLookUpParam,
  selectLoadingState,
  selectSearchParamData,
  selectQualityStatisticsAnaysisList,
  selectError,
  selectHistoryArray,
  selectHistogramArray,
  selectLineArray,
  selectXvarChartXYData,
  selectRChartXYData,
  selectProcDistributionXYdata,
  selectProcAbilityXYdata,
  (
    searchParam,
    lookUpParam,
    isLoading,
    searchParamData,
    AnalysisTargetData,
    error,
    historyArray,
    histogramArray,
    lineArray,
    xVarChartXYdata,
    RChartXYdata,
    ProcDistributionXYdata,
    ProcAbilityXYdata
  ) => {
    return {
      searchParam,
      lookUpParam,
      isLoading,
      searchParamData,
      AnalysisTargetData,
      error,
      historyArray,
      histogramArray,
      lineArray,
      xVarChartXYdata,
      RChartXYdata,
      ProcDistributionXYdata,
      ProcAbilityXYdata
    }
  }
)

export const qualityStatisticsAnaysisSelector = {
  searchParam: (state) => selectSearchParam(state[QualityStatisticsAnaysis]),
  lookUpParam: (state) => selectLookUpParam(state[QualityStatisticsAnaysis]),
  isLoading: (state) => selectLoadingState(state[QualityStatisticsAnaysis]),
  searchParamData: (state) => selectSearchParamData(state[QualityStatisticsAnaysis]),
  AnalysisTargetData: (state) =>
    selectQualityStatisticsAnaysisList(state[QualityStatisticsAnaysis]),
  historyArray: (state) => selectHistoryArray(state[QualityStatisticsAnaysis]),
  histogramArray: (state) => selectHistogramArray(state[QualityStatisticsAnaysis]),
  lineArray: (state) => selectLineArray(state[QualityStatisticsAnaysis]),
  xVarChartXYdata: (state) => selectXvarChartXYData(state[QualityStatisticsAnaysis]),
  RChartXYdata: (state) => selectRChartXYData(state[QualityStatisticsAnaysis]),
  ProcDistributionXYdata: (state) => selectProcDistributionXYdata(state[QualityStatisticsAnaysis]),
  ProcAbilityXYdata: (state) => selectProcAbilityXYdata(state[QualityStatisticsAnaysis]),
  error: (state) => selectError(state[QualityStatisticsAnaysis]),
  all: (state) => selectAllState(state[QualityStatisticsAnaysis])
}

export const QualityStatisticsAnaysis = slice.name
export const qualityStatisticsAnaysisReducer = slice.reducer
export const qualityStatisticsAnaysisAction = slice.actions
