import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Accordion from 'devextreme-react/accordion'
import Form, { SimpleItem, Label, GroupItem, RequiredRule, ButtonItem } from 'devextreme-react/form'
import SelectBox from 'devextreme-react/select-box'
import service from './data'
import {
  qualityStatisticsAnaysisAction,
  qualityStatisticsAnaysisSelector
} from '../../pages/QualityStatisticsAnaysis/slice'
import './SearchParam.scss'
import * as _ from 'lodash'
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box'
import DateBox from 'devextreme-react/date-box'
import moment from 'moment/moment'
const SearchParam = (props) => {
  const { searchParamData } = useSelector(qualityStatisticsAnaysisSelector.all)
  const dispatch = useDispatch()
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [validateChk, setValidateChk] = useState(false)
  const [searchParam, setSearchParam] = useState({
    inspType: 'TPS010003',
    itemId: null,
    procCode: null,
    inspRev: null,
    attSeq: null,
    endDate: new Date(),
    startDate: new Date()
  })

  useEffect(() => {
    dispatch(qualityStatisticsAnaysisAction.searchParamLoad(searchParam))
  }, [searchParam])

  const startDateOnValueChanged = (e) => {
    setSearchParam({
      ...searchParam,
      startDate: e.value
    })
  }
  const endDateOnValueChanged = (e) => {
    setSearchParam({
      ...searchParam,
      endDate: e.value
    })
  }

  const selectBoxDisplay = (item) => {
    return item && item.itemCode + ' / ' + item.itemId
  }
  const procDisplay = (item) => {
    return item && item.procCode + '/' + item.procName
  }
  const inspRevDisplay = (item) => {
    return item && item.inspRev
  }
  const attSeqDisplay = (item) => {
    return item && item.attSeq + '/' + item.attName
  }
  const mchIdDisplay = (item) => {
    return item && item.mchCode + '/' + item.mchName
  }
  const changeItem = (item) => {
    setSearchParam({
      ...searchParam,
      inspType: 'TPS010003',
      itemId: item.value,
      procCode: null,
      inspRev: null,
      attSeq: null
    })
  }
  const changeProc = (item) => {
    setSearchParam({
      ...searchParam,
      inspType: 'TPS010003',
      procCode: item.value,
      inspRev: null,
      attSeq: null
    })
  }
  const changeInspRev = (item) => {
    setSearchParam({
      ...searchParam,
      inspType: 'TPS010003',
      inspRev: item.value
    })
  }
  const clickInspRev = (e) => {
    dispatch(qualityStatisticsAnaysisAction.searchParamLoad(searchParam))
  }
  const changeAttSeq = (item) => {
    setSearchParam({
      ...searchParam,
      inspType: 'TPS010003',
      attSeq: item.value
    })
  }
  const changeMchId = (item) => {
    setSearchParam({
      inspType: 'TPS010003',
      attSeq: searchParam.attSeq,
      itemId: searchParam.itemId,
      mchId: searchParam.mchId,
      procCode: searchParam.procCode,
      inspRev: searchParam.inspRev,
      mchId: item.value,
      startDate: moment(startDate).format('YYYY-MM-DDT00:00:00'),
      endDate: moment(endDate).format('YYYY-MM-DD')
    })
  }
  const colCountByScreen = {
    sm: 2,
    md: 3
  }
  function screenByWidth(width) {
    return width < 720 ? 'sm' : 'md'
  }
  const openItemId = (e) => {
    console.log(searchParamData)
  }
  const searchBtnClick = (e) => {
    setValidateChk(true)
    dispatch(qualityStatisticsAnaysisAction.load(searchParam))
  }
  return (
    <div id="form-demo " className="paddings">
      <div className="widget-container ">
        <Accordion collapsible={true} multiple={true} animationDuration={300}>
          <Item>
            <Form
              id="form"
              readOnly={false}
              showColonAfterLabel={true}
              labelLocation={'top'}
              colCountByScreen={colCountByScreen}
              screenByWidth={screenByWidth}
            >
              <GroupItem colCount="2">
                <Label text="측정일자"></Label>
                <SimpleItem>
                  <DateBox
                    text="측정일자"
                    defaultValue={startDate}
                    value={searchParam.startDate}
                    type="date"
                    height="100%"
                    width="100%"
                    displayFormat="yyyy-MM-dd"
                    onValueChanged={startDateOnValueChanged}
                  />
                  <RequiredRule message="측정일자를 입력하세요" />
                  <Label visible={false}></Label>
                </SimpleItem>

                <SimpleItem>
                  <DateBox
                    text="측정일자"
                    defaultValue={endDate}
                    value={searchParam.endDate}
                    type="date"
                    height="100%"
                    width="100%"
                    displayFormat="yyyy-MM-dd"
                    onValueChanged={endDateOnValueChanged}
                  />
                  <RequiredRule message="측정일자를 입력하세요" />
                  <Label visible={false}></Label>
                </SimpleItem>
              </GroupItem>

              <SimpleItem>
                <SelectBox
                  showTitle={true}
                  dataSource={searchParamData.itemIdField}
                  searchEnabled={true}
                  displayExpr={selectBoxDisplay}
                  valueExpr="itemId"
                  onValueChanged={changeItem}
                  value={searchParam.itemId}
                ></SelectBox>
                <RequiredRule message="제품을 입력하세요" />
                <Label text="제품"></Label>
              </SimpleItem>

              <SimpleItem>
                <SelectBox
                  dataSource={searchParamData.procField}
                  searchEnabled={true}
                  displayExpr={procDisplay}
                  valueExpr="procCode"
                  onValueChanged={changeProc}
                  value={searchParam.procCode}
                ></SelectBox>
                <RequiredRule message="공정을 입력하세요" />
                <Label text="공정"></Label>
              </SimpleItem>

              <SimpleItem>
                <SelectBox
                  dataSource={searchParamData.inspRevField}
                  searchEnabled={true}
                  displayExpr={inspRevDisplay}
                  valueExpr="inspRev"
                  onValueChanged={changeInspRev}
                  onItemClick={clickInspRev}
                  value={searchParam.inspRev}
                ></SelectBox>
                <RequiredRule message="개정순번을 입력하세요" />
                <Label text="개정순번"></Label>
              </SimpleItem>

              <SimpleItem>
                <SelectBox
                  dataSource={searchParamData.attSeqField}
                  searchEnabled={true}
                  valueExpr="attSeq"
                  displayExpr={attSeqDisplay}
                  onValueChanged={changeAttSeq}
                  value={searchParam.attSeq}
                ></SelectBox>
                <RequiredRule message="검사항목을 입력하세요" />
                <Label text="검사항목"></Label>
              </SimpleItem>

              <SimpleItem>
                <SelectBox
                  dataSource={searchParamData.mchIdField}
                  searchEnabled={true}
                  valueExpr="mchId"
                  displayExpr={mchIdDisplay}
                  onValueChanged={changeMchId}
                  value={searchParam.mchId}
                ></SelectBox>
                <RequiredRule message="설비를 입력하세요" />
                <Label text="설비"></Label>
              </SimpleItem>

              <GroupItem colCount="1" colSpan="3">
                <ButtonItem
                  horizontalAlignment="center"
                  buttonOptions={{
                    text: '검색',
                    type: 'success',
                    // useSubmitBehavior: validateChk,
                    onClick: searchBtnClick
                  }}
                />
              </GroupItem>
            </Form>
          </Item>
        </Accordion>
      </div>
    </div>
  )
}
export default SearchParam
