import { createSelector, createSlice } from '@reduxjs/toolkit'

export const initialState = {
  success: false,
  code: 0,
  msg: '',
  rowSize: 0,
  list: [],
  isLoading: false,
  error: null
}

const reducers = {
  load: (state) => {
    state.isLoading = true
  },
  loadMore: (state) => {
    return state
  },
  loadSuccess: (state, { payload: { list } }) => {
    state.isLoading = false
    state.list = list
  },
  loadFail: (state, { payload: error }) => {
    state.isLoading = false
    state.error = error
  }
}

const name = 'UNSPLASH'

const slice = createSlice({
  name,
  initialState,
  reducers
})

const selectLoadingState = createSelector(
  (state) => state.isLoading,
  (isLoading) => isLoading
)

const selectProductList = createSelector(
  (state) => state.list,
  (list) => list
)

const selectError = createSelector(
  (state) => state.error,
  (error) => error
)

const selectAllState = createSelector(
  selectLoadingState,
  selectProductList,
  selectError,
  (isLoading, list, error) => {
    return { isLoading, list, error }
  }
)

export const unsplashSelector = {
  isLoading: (state) => selectLoadingState(state[UNSPLASH]),
  list: (state) => selectProductList(state[UNSPLASH]),
  error: (state) => selectError(state[UNSPLASH]),
  all: (state) => selectAllState(state[UNSPLASH])
}

export const UNSPLASH = slice.name
export const unsplashReducer = slice.reducer
export const unsplashAction = slice.actions
